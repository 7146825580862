<template>
    <HomePage/>
</template>
  
  <script>
  import HomePage from './components/HomePage.vue';
  
  export default {
      name: 'App',
      components: {
          HomePage
      }  
  }
  </script>
  
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  body {
    margin: 0;
  }
  </style>
  
