<template>
    <div class="skills">
        <div class="page">
            <div class="title">
                    Compétences
            </div>
    
            <div class="content">
                <div class="description">
                    <div class="paragraph">
                        <div class="title">
                            Langages back
                        </div>
                        <ul>
                            <li>PHP (Symfony)</li>
                            <li>Python (Django)</li>
                            <li>Kotlin (Spring)</li>
                        </ul>
                    </div>

                    <div class="paragraph">
                        <div class="title">
                            Langages front
                        </div>
                        <ul>
                            <li>HTML5</li>
                            <li>JS (VueJS)</li>
                            <li>CSS3</li>
                        </ul>
                    </div>

                    <div class="paragraph">
                        <div class="title">
                            Base de données
                        </div>
                        <ul>
                            <li>Postgresql</li>
                            <li>MySQL</li>
                            <li>SQL Server</li>
                        </ul>
                    </div>

                    <div class="paragraph">
                        <div class="title">
                            Méthodologie
                        </div>
                        <ul>
                            <li>Architecture Hexagonale</li>
                            <li>Test Driven Development (TDD)</li>
                            <li>API Rest</li>
                        </ul>
                    </div>

                    <div class="paragraph">
                        <div class="title">
                            Sys Admin
                        </div>
                        <ul>
                            <li>Apache</li>
                            <li>Nginx</li>
                            <li>Linux</li>
                        </ul>
                    </div>

                    <div class="paragraph">
                        <div class="title">
                            Versionnement
                        </div>
                        <ul>
                            <li>Git</li>
                            <li>SVN</li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </template>
    
    
    <style scoped>
        .skills {
            min-height: 100vh;
            background-color: F0F2F5;
        }

        .skills .content .title {
            font-size: 1.2em;
        }
    
        .description {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            padding: 4%;
            text-align: left;
            background-color: #EEE;
            margin-left: 2%;
            margin-top: 2%;
            min-height: 50vh;
        }
    
        .description .paragraph {
            margin-top: 2%;
        }

        @media (max-width: 800px) {
            .skills .content .title {
                font-size: 1em;
            }
        }

        @media (max-width: 600px) {
            .description {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }

            .skills .content .title {
                font-size: 0.8em;
            }
        }
    </style>