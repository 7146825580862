<template>
    <div class="contact">
        <div class="page">
            <div class="title">
                Contact
            </div>
    
            <div class="content">
                <div class="description">
                    <div class="paragraph">
                        <div class="title">
                            Réseaux sociaux
                        </div>
                        <ul>
                            <li><a href="https://www.linkedin.com/in/%C3%A9milien-berth%C3%A9l%C3%A9my-08a737141/">Mon profil Linkedin</a></li>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </template>
    
    
    <style scoped>
        .contact {
            min-height: 100vh;
            background-color: F0F2F5;
        }

        .contact .content .title {
            font-size: 1.2em;
        }
    
        .description {
            display: block;
            padding: 4%;
            text-align: left;
            background-color: #EEE;
            margin-left: 2%;
            margin-top: 2%;
            min-height: 50vh;
        }
    
        .description .paragraph {
            margin-top: 2%;
        }
    </style>