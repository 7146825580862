<template>
    <div class="experience">
        <div class="page">
            <div class="title">
                    Expérience
            </div>
    
            <div class="content">
                <div class="description">
                    <div class="dates">
                        <div class="title">
                            Professionnelle
                        </div>

                        <div class="date">
                            2019 - Maintenant
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            Développeur
                        </div>

                        <div class="paragraph">
                            Entreprise <a href="http://www.planilog.com/">Planilog</a> du groupe <a href="https://www.totalspecificsolutions.com/">TSS</a>
                        </div>
                        

                        <div class="paragraph">
                            Développement et maintenance de la solution Saas Planilog, logiciel de planification et d'ordonnancement
                        </div>
                    
                        <div class="paragraph">
                            Développement de différentes API en Kotlin.<br>
                            Mise en place de tests unitaires et d'intégration.<br>
                            Suivi des normes OData et contrat d'interface Swagger.
                        </div>
        
                        <div class="paragraph">
                            Développement du back en PHP
                        </div>

                        <div class="paragraph">
                            Architecture hexagonale
                        </div>
                    </div>
                    
                    
                </div>

                <div class="description">
                    <div class="dates">
                        <div class="date">
                            2015 - 2019
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            Développeur Confirmé
                        </div>

                        <div class="paragraph">
                            Entreprise FremenCorp (Troyes)
                        </div>
                        

                        <div class="paragraph">
                            FremenCorp SAS est une société de prestation de services spécialisée dans les jeux vidéos et plus précisément les Serious Games.<br>
                            Au sein de cette entreprise je travaille au développement de serious games que ce soit côté client ou côté serveur.<br>
                            Ainsi j'ai pu participer entre autre au développement du jeu "Esprit de Service II" pour Axa.<br>
                            Ce COOC gamifié a pour objectif de sensibiliser les collaborateurs de l'entreprise sur différents aspects des relations 
                            clients et de mettre en avant la chaîne de valeur de l'entreprise. 
                        </div>
                    
                        <div class="paragraph">
                            Django / REST Framework<br>
                            Backbone js / VueJS / NodeJs / JQuery / Webpack<br>
                            HTML5 / CSS3<br>
                            Postgresql
                        </div>
                    </div>
                </div>

                <div class="description">
                    <div class="dates">
                        <div class="date">
                            2010 - 2015
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            Développeur Web
                        </div>

                        <div class="paragraph">
                            Entreprise WebApp (Angers)
                        </div>
                        

                        <div class="paragraph">
                            Ma mission au sein de cette entreprise était de concevoir des sites et applications web pour les clients
                            et de travailler sur le développement d'outils internes. 
                        </div>

                        <div class="paragraph">
                            <ul>
                                <li>Conception et optimisation de bases de données (MySQL).</li>
                                <li>Intégration HTML et CSS, intégration et découpage des fichiers psd fournit par des graphistes.</li>
                                <li>Développements Javascripts en utilisant des librairies comme JQuery.</li>
                                <li>Développements PHP via un Framework PHP qui permet de faire du mapping objet relationnel et d'utiliser une structure MVC.</li>
                                <li>Utilisation d'outils de versionnages (SVN) et de comparaison de fichiers.</li>
                                <li>Mise en production dans différents environnements.</li>
                                <li>Relations clients, pour connaître les besoins, conseiller et orienter sur les choix à prendre.</li>
                            </ul>
                        </div>
                    
                        <div class="paragraph">
                            compétences particulières :<br>
                            J'ai travaillé sur un projet dans le biomédical (<a href="http://fibrometer.com">fibrometer</a>) qui disposait d'un outil interne développé en Delphi.
                            J'ai dû maintenir cet outil et développer de nouvelles fonctionnalités.<br>
                            Dans le cadre de ce projet j'ai dû également développer un outil en C# qui permettait de gérer les échanges
                            de données entre la BDD et un logiciel de gestion (EBP).<br><br>
                            Bien que WebApp était spécialisée dans le sur-mesure, il m'est arrivé sur certains projets de mettre en place des CMS tel que Wordpress sur demande du client. 
                        </div>
                    </div>
                </div>

                <div class="description">
                    <div class="dates">
                        <div class="title">
                            Études
                        </div>

                        <div class="date">
                            2009-2010
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            Licence Professionnelle Logiciels libres et propriéraires
                        </div>

                        <div class="paragraph">
                            Faculté des Sciences (Angers) <br>
                            Cette licence professionnelle m'a permis d'approfondir mes connaissances dans la programmation et m'a ouvert sur le monde des logiciels libres. <br><br>
                            Stage de 4 mois dans l'entreprise WebApp (Angers) <br>
                            Ma mission durant ce stage était de développer un outil d'analyse de traffic basé sur le logiciel Piwik.
                        </div>
                    </div>
                </div>

                <div class="description">
                    <div class="dates">
                        <div class="date">
                            2007-2009
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            DUT Informatique
                        </div>

                        <div class="paragraph">
                            IUT Informatique (Reims) <br>
                            Au cours de mon DUT, j'ai appris la programmation orientée objet. Ainsi j'ai pu apprendre les langages C++, Java, C#, PHP, HTML, MySQL, Oracle <br><br>
                            Stage de 8 semaines dans l'entreprise ArtTouch (Reims) <br>
                            Ma mission durant ce stage était de travailler sur des fonctionnalités Javascript ou PHP et l'intégration HTML/CSS de boutiques en ligne sous les CMS Prestashop et SPIP.
                        </div>
                    </div>
                </div>

                <div class="description">
                    <div class="dates">
                        <div class="date">
                            2007
                        </div>
                    </div>

                    <div class="paragraphs">
                        <div class="paragraph name">
                            Obtention du BAC série S avec mention
                        </div>

                        <div class="paragraph">
                            Lycée Chrestien de Troyes (Troyes)
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    
    <style scoped>
        .experience {
            min-height: 100vh;
            background-color: F0F2F5;
        }
    
        .dates {
            background-color: #EEE;
            width: 25%;
        }

        .page .dates .title {
            font-size: 1.2em;
        }
    
        .description {
            display: flex;
            justify-content: center;
            padding: 4%;
            text-align: left;
            background-color: #EEE;
            margin: 2%;
            width: 90%;
        }
    
        .description .paragraphs {
            width: 75%;
        }

        .description .paragraph {
            margin-left: 2%;
            margin-top: 2%;
        }

        .date {
            margin-top: 2%;
            background-color: #942221;
            color: white;
            display: inline-block;
            padding: 4%;
            font-weight: bold;
        }

        .paragraph.name {
            color: #942221;
            font-weight: bold;
        }

        @media (max-width: 1400px) {
            .page .dates .title {
                font-size: 1em;
            }
        }

        @media (max-width: 600px) {
            .description {
                display: block;
            }

            .dates {
                width: 100%;
            }

            .date {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .paragraph.name {
                font-size: 1em;
            }

            .description .paragraphs {
                width: 90%;
            }

            .description .paragraph {
                margin-top: 20px;
            }
        }

    </style>