<template>
    <div class="menu" :class="menuPosition">
        <LogoBlock2 :scale="0.9" :identifier="'menuLogo'" :width="600" :height="200" ref="logo2" class="logo2"/>
        <div class="items">
            <div class="item" @click="menuScrollAbout">À propos</div>
            <div class="item" @click="menuScrollExperience">Expérience</div>
            <div class="item" @click="menuScrollSkills">Compétences</div>
            <div class="item" @click="menuScrollContact">Contact</div>
        </div>
        <div class="cvButton"><a href="./emilien_berthelemy_cv.pdf" download="emilien_berthelemy_cv.pdf">CV</a></div>
    </div>
</template>

<script>
    import LogoBlock2 from './LogoBlock2.vue'

    export default {
        components: {
            LogoBlock2
        },
        data() {
            return {
                menuPosition: "bottom"
            }
        },
        name: 'MenuBlock',
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll () {
                let menuHeight = document.querySelector(".menu").clientHeight
                if (document.body.getBoundingClientRect().top + window.innerHeight - menuHeight > 0) {
                    if (this.menuPosition !== "bottom") {
                        this.menuPosition = "bottom"
                        this.logoLaunchAnimation()
                    }
                    
                } else {
                    if (this.menuPosition !== "top") {
                        this.menuPosition = "top"
                        this.logoLaunchAnimation()
                    }
                    
                }
            },
            logoLaunchAnimation() {
                this.$refs.logo2.init();
            },
            menuScrollTo(className) {
                let elem = document.querySelector("." + className)
                let elemRect = elem.getBoundingClientRect()
                window.scrollBy({
                    top: elemRect.top - 50,
                    left: 0,
                    behavior: 'smooth'
                })
            },
            menuScrollAbout() {
                this.menuScrollTo("about")
            },
            menuScrollExperience() {
                this.menuScrollTo("experience")
            },
            menuScrollSkills() {
                this.menuScrollTo("skills")
            },
            menuScrollContact() {
                this.menuScrollTo("contact")
            }
        }
    }
</script>


<style scoped>
    .menu.bottom{
        position: absolute;
        z-index: 10;
        left: 0px;
        bottom: 0px;
        background-color: #1C1C1C;
        width: 100%;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
    }   

    .menu.top{
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 10;
        background-color: #1C1C1C;
        width: 100%;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        border-bottom: 2px #942221 solid;
        text-shadow: 0px 4px 4px #000;
    }

    .menu .items{
        margin : 0;
        padding: 0;
        display: inline-block;
    }

    .menu .item{
        list-style: none;
        text-decoration: none;
        float : left;
        padding: 15px 5px 10px 15px;
    }

    .menu .item{
        list-style: none;
        text-decoration: none;
        color: white;
        text-shadow: 0px 2px 2px #000;
        border-bottom: 4px #1C1C1C solid;
    }

    .menu .item:hover, .menu .item.selected{
        color: #942221;
        border-bottom: 4px #942221 solid;
        cursor: pointer;
    }

    .menu.bottom #menuLogo {
        height: 65px;
        position: absolute;
        top: 1px;
        left: 20%;
        display: none;
    }

    .menu.top #menuLogo {
        height: 65px;
        position: absolute;
        top: 1px;
        left: 20%;
    }

    .cvButton {
        position: fixed;
        right: 20px;
        bottom: 40px;
        font-weight: bold;
    }

    .cvButton a {
        background-color: #942221;
        padding: 20px;
        border-radius: 30px;
        color: white;
        box-shadow: 5px 5px 5px #AAA;
        text-shadow: none;
    }

    .cvButton a:hover {
        color: #AAA;
    }

    .menu.bottom .cvButton {
        display: none;
    }

    @media (max-width: 1200px) {
        .menu.bottom #menuLogo {
            left: 5%;
        }

        .menu.top #menuLogo {
            left: 5%;
        }
    }

    @media (max-width: 800px) {
        .menu.bottom #menuLogo {
            display: none;
        }

        .menu.top #menuLogo {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .menu.bottom, .menu.top {
            padding-top: 10px;
            font-size: 12px;
        }
    }

    @media (max-width: 400px) {
        .menu .item{
            padding: 5px 2px 2px 5px;
        }
    }

</style>