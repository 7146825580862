<template>
<div class="about">
    <div class="page">
        <div class="title">
                À propos
        </div>

        <div class="content">
            <div class="photo">
                <img src="../assets/photo.jpg">
            </div>

            <div class="description">
                <div class="paragraph">
                    Passionné par le développement, j'adore apprendre de nouvelles méthodes.
                </div>
                
                <div class="paragraph">
                    De par mon cursus j'ai eu la chance de pouvoir toucher différents langages de programmations.
                    PHP, Python, Kotlin avec différents Frameworks, Symfony, Django, Spring
                </div>

                <div class="paragraph">
                    Je suis un développeur Full Stack, bien que ces dernières années je me sois spécialisé dans le développement back.<br>
                    En effet à Planilog je travaille activement sur la conception d'API en kotlin.<br>
                    Mais j'ai une expérience forte en front de par mon passage chez Fremencorp où nous faisions des applications single pages en Backbone.js et vue.js
                </div>
                
            </div>
        </div>
    </div>
</div>
</template>

<style>
    #app {
        width: 100%;
        overflow: hidden;
    }
</style>


<style scoped>
    .about {
        min-height: 100vh;
        background-color: F0F2F5;
    }

    .about .content {
        display: flex;
        justify-content: center;
    }

    .photo {
        margin: 2%;
        min-height: 50vh;
        width: 30vw;
    }

    .photo img {
        height: 100%;
        object-position: 50% 0;
        object-fit: cover;
        width: 30vw;
    }

    .description {
        padding: 4%;
        text-align: left;
        background-color: #EEE;
        margin-bottom: 2%;
        margin-left: 2%;
        margin-top: 2%;
        width: 100vh;
        min-height: 50vh;
    }

    .description .paragraph {
        margin-top: 2%;
    }

    

    @media (min-width: 1400px) {
        .photo {
            width: 20vw;
        }

        .photo img {
            width: 20vw;
        }
    }

    @media (max-width: 600px) {
        .about .content {
            display: block;
        }

        .photo {
            margin: auto;
            margin-top: 10px;
            min-height: 100%;
            width: 100%;
        }

        .photo img {
            width: 50vw;
        }

        .description {
            padding: 4%;
            text-align: left;
            background-color: #EEE;
            margin-bottom: 2%;
            margin-left: 2%;
            margin-top: 2%;
            width: 90%;
            min-height: 100%;
        }

    }
</style>