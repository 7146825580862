<template>
    <canvas :id="logoIdentifier" :width="logoWidth" :height="logoHeight"></canvas>
</template>

<script>
import { ref } from 'vue'

const canvas = ref(null);
const ctx = ref(null);

let letterE = {
    x: 0,
    y: 0,
    xMax: 450,
    vx: 9,
    color: "#d5d5d5",
    path: new Path2D('m 66.746128,28.737524 9,-21.08035 -60.000003,18.42856 0,113.571436 60.000003,18 -9,-20.72688 -32.571431,-9.92475 0,-32.348366 32.571431,0 0,-18 -32.571431,0 0,-38.08929 z'),
    draw: function () {
        let path2 = new Path2D(this.path);
        const p2 = new Path2D();
        p2.addPath(path2, {e: this.x, f: this.y}); 
        let alpha = ((this.x * 100)/250)/100;
        ctx.value.globalAlpha = alpha;
        ctx.value.fillStyle = this.color;
        ctx.value.fill(p2);
    },
};

let letterPipe = {
    x: 300,
    y: 0,
    color: "#9d9d9d",
    path: new Path2D('m 27.559901,6.6375691 -8.986682,20.3864099 -0.03793,108.219631 9.062538,20.70354 9.434129,-20.88448 0.0099,-108.050541 -9.404398,-21.0649799 c 0,0 -0.06475,0.69042 -0.07763,0.69042 z'),
    draw: function () {
        let path2 = new Path2D(this.path);
        const p2 = new Path2D();
        p2.addPath(path2, {e: this.x, f: this.y}); 
        let alpha = ((this.x * 100)/250)/100;
        ctx.value.globalAlpha = alpha;
        ctx.value.fillStyle = this.color;
        ctx.value.fill(p2);
    },
};

let letterB = {
    x: 550,
    y: -5,
    xMin: 312,
    vx: 10,
    color: "#2d2d2d",
    path: new Path2D('m 28.562352,12.446963 9.405248,21.10051 36.698095,11.0503 -36.717056,36.71916 0.0096,18.10296 35.381973,31.390137 -35.457726,10.70646 -9.366907,20.83589 59.999017,-18.04089 -0.012,-18.04732 -41.016976,-36.471097 41.065816,-41.02996 -0.0267,-17.97392 -59.920924,-18.43681 z'),
    draw: function () {
        let path2 = new Path2D(this.path);
        const p2 = new Path2D();
        p2.addPath(path2, {e: this.x, f: this.y}); 
        let alpha = ((this.x * 100)/250)/100;
        ctx.value.globalAlpha = alpha;
        ctx.value.fillStyle = this.color;
        ctx.value.fill(p2);
    },
};

let reqAnimFrame = window.mozRequestAnimationFrame    ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame     ||
    window.oRequestAnimationFram ||
    requestAnimationFrame
    ;

let isAnimationLaunched = false

export default {
    name: 'LogoBlock2',
    props: ['scale', 'identifier', 'width', 'height'],
    data() {
        return {
            logoIdentifier: this.identifier,
            logoWidth: this.width,
            logoHeight: this.height,
        }
    },
    methods: {
        draw() {
            ctx.value.clearRect(0, 0, canvas.value.width * 2, canvas.value.height * 2);
            let shouldLetterEBeDrawn = false;
            let shouldLetterBBeDrawn = false;

            letterE.draw();
            letterPipe.draw();
            letterB.draw();

            if (letterE.x < letterE.xMax) {
                letterE.x += letterE.vx;
                shouldLetterEBeDrawn = true;
            }
            
            if (letterB.x > letterB.xMin) {
                letterB.x -= letterB.vx;
                shouldLetterBBeDrawn = true;
            }

            if (shouldLetterEBeDrawn || shouldLetterBBeDrawn) {
                reqAnimFrame(()=>this.draw());
            }
        },
        init() {
            if (!isAnimationLaunched) {
                canvas.value = document.getElementById(this.identifier);
                ctx.value = canvas.value.getContext("2d");
                ctx.value.clearRect(0, 0, canvas.value.width * 2, canvas.value.height * 2);
                ctx.value.scale(this.scale, this.scale);
                isAnimationLaunched = true;
            }

            letterE.x = 0;
            letterE.xMax = (50 * (canvas.value.width) / 100) - 60;
            letterPipe.x = (50 * (canvas.value.width)) / 100;
            letterB.x = canvas.value.width;
            letterB.xMin = (50 * (canvas.value.width) / 100) + 15;

            reqAnimFrame(()=>this.draw());
        }
    },
    mounted: function(){
        this.init()
    }
}
</script>

<style scoped>
</style>