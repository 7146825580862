<template>
  <div class="homePage">
    <LogoBlock :scale="0.8" :identifier="'homeLogo'" :width="600" :height="200" ref="logo" class="logo"/>
    <MenuBlock />
  </div>
  <AboutPage />
  <ExperiencePage />
  <SkillsPage />
  <ContactPage />
</template>

<script>
import MenuBlock from './MenuBlock.vue'
import LogoBlock from './LogoBlock.vue'
import AboutPage from './AboutPage.vue'
import ExperiencePage from './ExperiencePage.vue'
import SkillsPage from './SkillsPage.vue'
import ContactPage from './ContactPage.vue'

export default {
    name: 'HomePage',
    components: {
    MenuBlock,
    LogoBlock,
    AboutPage,
    ExperiencePage,
    SkillsPage,
    ContactPage
},
    created () {
            window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
  methods: {
    handleScroll () {
        if (document.body.getBoundingClientRect().top == 0) {
            this.logoLaunchAnimation()
        }
    },
    logoLaunchAnimation() {
        this.$refs.logo.init();
    }
  }
}

</script>

<style>
    .homePage {
        min-height: 100vh;
        background: url("../assets/bg.png");
        #homeLogo {
            margin-top: 40vh;
            position: relative;
            left: 70px;
        }
        overflow:hidden;
    }

    .page {
        width: 90%;
        margin: auto;
        margin-top: 100px;
        padding: 20px;
    }

    @media (min-width: 1250px) {
        .page {
            width: 60%;
        }
    }

    .page .title {
        border-left: 3px solid #942221;
        font-size: 2em;
        padding-left: 10px;
        text-align: left;
        text-transform: uppercase;
        text-shadow: 0px 0px 1px #000;
    }
        

    a {
        color: rgb(0, 63, 126);
        text-decoration: none;
    }

    a:hover {
        color: rgb(0, 20, 39);
        text-decoration: none;
    }

    @media (max-width: 600px) {
        .homePage {
            #homeLogo {
                margin-top: 30vh;
                left: -50px;
            }
        }
    }

    @media (max-width: 400px) {
        .homePage {
            #homeLogo {
                left: -70px;
            }
        }
    }
</style>
